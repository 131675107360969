import * as actionTypes from '../action.js';
import axios from '../../shared/axios-details';

export const authStart = () =>{
    return {
        type:actionTypes.AUTH_START
    };
};

export const authSuccess = (token, name, profile_photo, roles) =>{
    return {
        type:actionTypes.AUTH_SUCCESS,
        token:token,
        name:name,
        profile_photo:profile_photo,
        roles:roles
    }
}


export const authFailure = (error) =>{
    return {
        type:actionTypes.AUTH_FAILED,
        error:error
    }
}

export const logout=() =>{
    localStorage.removeItem('influencerToken');
    localStorage.removeItem('influencerName');
    localStorage.removeItem('influencerProfile');
    localStorage.removeItem('MadFanAdminRoles');
    return {
        type:actionTypes.INIT_LOGOUT
    }
}

export const auth = (email, password) =>{
    return dispatch =>{
        dispatch(authStart());
        const authData={
            email:email,
            password:password,
        }
        //console.log(authData);
        axios.post('/login.php',authData)
        .then(res => {
            if(res.data.status){
                console.log(res.data);
                let roles = []
                if(res.data.roles){
                    roles = res.data.roles.split(',')
                }
                //save to local
                localStorage.setItem('influencerToken',res.data.data.id);
                localStorage.setItem('influencerName',res.data.data.name);
                localStorage.setItem('influencerProfile',res.data.data.profile_photo);
                localStorage.setItem('MadFanAdminRoles',res.data.roles);
                //dispatch
                dispatch(authSuccess(res.data.data.id, res.data.data.name, res.data.data.profile_photo, roles));
            }
            else{
                dispatch(authFailure(res.data.message));
            }
            console.log(res.data);
        }).catch(err =>{
            console.log(err);
            dispatch(authFailure("Server Error"));
        });
    };
}


export const setAuthRedirect= (path) =>{
    return{
        type:actionTypes.SET_AUTH_REDIRECT_PATH,
        path:path
    }
}

export const authCheckStatus= () =>{
    return dispatch =>{
        const influencerToken = localStorage.getItem('influencerToken');
        const influencerName =localStorage.getItem('influencerName'); 
        const influencerProfile =localStorage.getItem('influencerProfile'); 
        let roles = localStorage.getItem('MadFanAdminRoles'); 
        if(roles){
            console.log(roles)
            roles = roles.split(',')
        }
        else{
            roles = []
        }
        if(!influencerToken){
            dispatch(logout());
        }
        else{
            dispatch(authSuccess(influencerToken, influencerName, influencerProfile, roles));
        }
    }
}

export  default{
    auth, logout, setAuthRedirect, authCheckStatus
} ;