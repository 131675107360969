/**
 *
 * CelebNavigation Component
 *
 */
import React, { useState } from "react";
import AllActions from "../../store/actions";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import logo from "../../images/logo.png";
import bell from "../../images/bell.png";
import { useSelector } from "react-redux";

import {
    faThLarge,
    faUser,
    faEdit,
    faEnvelopeOpenText,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuItem } from "@material-ui/core";
import order from "../../images/order.png";
import influencer from "../../images/influencer.png";
import manager from "../../images/manager.png";
import coupon from "../../images/coupon.png";
import referral from "../../images/referral.png";
import user from "../../images/user.png";

import order_grey from "../../images/order_grey.png";
import influencer_grey from "../../images/influencer_grey.png";
import manager_grey from "../../images/manager_grey.png";
import coupon_grey from "../../images/coupon_grey.png";
import referral_grey from "../../images/referral_grey.png";
import user_grey from "../../images/user_grey.png";

import { CelebRoute } from "./enums";

type Props = {
    /** internal */
    classes: any;

    /** internal */
    formatMessage: Function;

    /** class applied to top level container */
    className?: any;

    /** style applied to top level container */
    style?: Object;

    children: any;

    device: string;

    route?: string;

    celebName: string;

    noOfNotifs: number;

    dp: any;

    onRouteChange: (route: string) => void;

    onNotifClick: () => void;

    onProfileClick: () => void;
};

function CelebNavigation(props: Props) {
    let {
        classes,
        style,
        className,
        formatMessage,
        device = "large",
        route,
        celebName,
        noOfNotifs,
        dp,
        children,
        onRouteChange,
        onNotifClick,
        onProfileClick,
    } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useDispatch();
    if (!route) {
        for (let r of Object.keys(CelebRoute)) {
            if (window.location.href.indexOf(CelebRoute[r]) != -1) {
                route = CelebRoute[r];
                break;
            }
        }
        if (!route) {
            route = CelebRoute.Dashboard;
        }
    }

    const auth = useSelector((state) => state.auth)
    console.log(auth);

    const [curRoute, setCurRoute] = useState(route);
    const [openMenu, setOpenMenu] = useState(false);

    function handleCloseMenu() {
        setOpenMenu(false);
    }
    const getRouteName = () => {
        if (curRoute === CelebRoute.Dashboard)
            return formatMessage(messages.dashboard);
        if (curRoute === CelebRoute.Orders)
            return formatMessage(messages.orders);
        if (curRoute === CelebRoute.Influencers)
            return formatMessage(messages.influencers);
        if (curRoute === CelebRoute.Managers)
            return formatMessage(messages.managers);
        if (curRoute === CelebRoute.Coupons)
            return formatMessage(messages.coupons);
        if (curRoute === CelebRoute.Referrals)
            return formatMessage(messages.referrals);
        if (curRoute === CelebRoute.Users)
            return formatMessage(messages.users);
        if (curRoute === CelebRoute.Quiz)
            return formatMessage(messages.quiz);
        if (curRoute === CelebRoute.Quiz)
            return formatMessage(messages.quiz);
        if (curRoute === CelebRoute.Recharge)
            return formatMessage(messages.recharge);
        if (curRoute === CelebRoute.Question)
            return formatMessage(messages.question);
        if (curRoute === CelebRoute.Admin)
            return formatMessage(messages.admin);
        if (curRoute === CelebRoute.Profile) return formatMessage(messages.profile);
    };

    return (
        <div className={classnames(classes.container, className)} style={style}>
            {device === "large" || device === "small" ? (
                <div >
                    <div className={classes.largeDevice}>
                        <section className={classes.leftBar}>
                            <img src={logo} alt="Logo" className={classes.logo} />
                            <div className={classes.wrap}>
                                <Button
                                    onClick={() => {
                                        setCurRoute(CelebRoute.Dashboard);
                                        onRouteChange(CelebRoute.Dashboard);
                                    }}
                                    className={classnames(classes.iconBtn, {
                                        [classes.iconBtnSelected]:
                                            curRoute === CelebRoute.Dashboard,
                                    })}
                                >
                                    <div className={classes.iconWrap}>
                                        <FontAwesomeIcon
                                            className={classnames(classes.icon, {
                                                [classes.iconSelected]:
                                                    curRoute === CelebRoute.Dashboard,
                                            })}
                                            icon={faThLarge}
                                            style={
                                                curRoute === CelebRoute.Dashboard
                                                    ? {
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                    }
                                                    : {}
                                            }
                                        />
                                        <div
                                            style={{ fontFamily: "Poppins" }}
                                            className={classnames(classes.text, {
                                                [classes.textSelected]:
                                                    curRoute === CelebRoute.Dashboard,
                                            })}
                                        >
                                            {formatMessage(messages.dashboard)}
                                        </div>
                                    </div>
                                </Button>
                                {/* Orders */}
                                {
                                    auth.roles && auth.roles.includes("order")
                                        ?
                                        <Button
                                            className={classnames(classes.iconBtn, {
                                                [classes.iconBtnSelected]: curRoute === CelebRoute.Orders,
                                            })}
                                            onClick={() => {
                                                setCurRoute(CelebRoute.Orders);
                                                onRouteChange(CelebRoute.Orders);
                                            }}
                                        >
                                            <div className={classes.iconWrap}>
                                                {curRoute === CelebRoute.Orders ? <div className={classnames(classes.icon, {
                                                    [classes.iconSelected]:
                                                        curRoute === CelebRoute.Orders,
                                                })} style={
                                                    curRoute === CelebRoute.Orders
                                                        ? {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                        }
                                                        : {}
                                                }><img src={order} /></div> : <img src={order_grey} style={
                                                    curRoute === CelebRoute.Orders
                                                        ? { width: 88, height: 88, overflow: "hidden" }
                                                        : {}
                                                } />}
                                                <div
                                                    style={{ fontFamily: "Poppins" }}
                                                    className={classnames(classes.text, {
                                                        [classes.textSelected]:
                                                            curRoute === CelebRoute.Orders,
                                                    })}
                                                >
                                                    {formatMessage(messages.orders)}
                                                </div>
                                            </div>
                                        </Button>
                                        : null
                                }
                                {/* Influencer */}
                                {
                                    auth.roles && auth.roles.includes("influencer")
                                        ?
                                        <Button
                                            className={classnames(classes.iconBtn, {
                                                [classes.iconBtnSelected]: curRoute === CelebRoute.Influencers,
                                            })}
                                            onClick={() => {
                                                setCurRoute(CelebRoute.Influencers);
                                                onRouteChange(CelebRoute.Influencers);
                                            }}
                                        >
                                            <div className={classes.iconWrap}>
                                                {curRoute === CelebRoute.Influencers ? <div className={classnames(classes.icon, {
                                                    [classes.iconSelected]:
                                                        curRoute === CelebRoute.Influencers,
                                                })} style={
                                                    curRoute === CelebRoute.Influencers
                                                        ? {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                        }
                                                        : {}
                                                }><img src={influencer} /></div> : <img src={influencer_grey} style={
                                                    curRoute === CelebRoute.Influencers
                                                        ? { width: 88, height: 88, overflow: "hidden" }
                                                        : {}
                                                } />}
                                                <div
                                                    style={{ fontFamily: "Poppins" }}
                                                    className={classnames(classes.text, {
                                                        [classes.textSelected]:
                                                            curRoute === CelebRoute.Influencers,
                                                    })}
                                                >
                                                    {formatMessage(messages.influencers)}
                                                </div>
                                            </div>
                                        </Button>
                                        : null
                                }

                                {/* Manager */}
                                {
                                    auth.roles && auth.roles.includes("manager")
                                        ?
                                        <Button
                                            className={classnames(classes.iconBtn, {
                                                [classes.iconBtnSelected]: curRoute === CelebRoute.Managers,
                                            })}
                                            onClick={() => {
                                                setCurRoute(CelebRoute.Managers);
                                                onRouteChange(CelebRoute.Managers);
                                            }}
                                        >
                                            <div className={classes.iconWrap}>
                                                {curRoute === CelebRoute.Managers ? <div className={classnames(classes.icon, {
                                                    [classes.iconSelected]:
                                                        curRoute === CelebRoute.Managers,
                                                })} style={
                                                    curRoute === CelebRoute.Managers
                                                        ? {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                        }
                                                        : {}
                                                }><img src={manager} /></div> : <img src={manager_grey} style={
                                                    curRoute === CelebRoute.Managers
                                                        ? { width: 88, height: 88, overflow: "hidden" }
                                                        : {}
                                                } />}
                                                <div
                                                    style={{ fontFamily: "Poppins" }}
                                                    className={classnames(classes.text, {
                                                        [classes.textSelected]:
                                                            curRoute === CelebRoute.Managers,
                                                    })}
                                                >
                                                    {formatMessage(messages.managers)}
                                                </div>
                                            </div>
                                        </Button>
                                        : null
                                }

                                {/* Coupon */}
                                {
                                    auth.roles && auth.roles.includes("coupon")
                                        ?
                                        <Button
                                            className={classnames(classes.iconBtn, {
                                                [classes.iconBtnSelected]: curRoute === CelebRoute.Coupons,
                                            })}
                                            onClick={() => {
                                                setCurRoute(CelebRoute.Coupons);
                                                onRouteChange(CelebRoute.Coupons);
                                            }}
                                        >
                                            <div className={classes.iconWrap}>
                                                {curRoute === CelebRoute.Coupons ? <div className={classnames(classes.icon, {
                                                    [classes.iconSelected]:
                                                        curRoute === CelebRoute.Coupons,
                                                })} style={
                                                    curRoute === CelebRoute.Coupons
                                                        ? {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                        }
                                                        : {}
                                                }><img src={coupon} /></div> : <img src={coupon_grey} style={
                                                    curRoute === CelebRoute.Coupons
                                                        ? { width: 88, height: 88, overflow: "hidden" }
                                                        : {}
                                                } />}
                                                <div
                                                    style={{ fontFamily: "Poppins" }}
                                                    className={classnames(classes.text, {
                                                        [classes.textSelected]:
                                                            curRoute === CelebRoute.Coupons,
                                                    })}
                                                >
                                                    {formatMessage(messages.coupons)}
                                                </div>
                                            </div>
                                        </Button>
                                        : null
                                }

                                {/* Coupon */}
                                {
                                    auth.roles && auth.roles.includes("referral")
                                        ?
                                        <Button
                                            className={classnames(classes.iconBtn, {
                                                [classes.iconBtnSelected]: curRoute === CelebRoute.Referrals,
                                            })}
                                            onClick={() => {
                                                setCurRoute(CelebRoute.Referrals);
                                                onRouteChange(CelebRoute.Referrals);
                                            }}
                                        >
                                            <div className={classes.iconWrap}>
                                                {curRoute === CelebRoute.Referrals ? <div className={classnames(classes.icon, {
                                                    [classes.iconSelected]:
                                                        curRoute === CelebRoute.Referrals,
                                                })} style={
                                                    curRoute === CelebRoute.Referrals
                                                        ? {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                        }
                                                        : {}
                                                }><img src={referral} /></div> : <img src={referral_grey} style={
                                                    curRoute === CelebRoute.Referrals
                                                        ? { width: 88, height: 88, overflow: "hidden" }
                                                        : {}
                                                } />}
                                                <div
                                                    style={{ fontFamily: "Poppins" }}
                                                    className={classnames(classes.text, {
                                                        [classes.textSelected]:
                                                            curRoute === CelebRoute.Referrals,
                                                    })}
                                                >
                                                    {formatMessage(messages.referrals)}
                                                </div>
                                            </div>
                                        </Button>
                                        : null
                                }

                                {/* Coupon */}
                                {
                                    auth.roles && auth.roles.includes("user")
                                        ?
                                        <Button
                                            className={classnames(classes.iconBtn, {
                                                [classes.iconBtnSelected]: curRoute === CelebRoute.Users,
                                            })}
                                            onClick={() => {
                                                setCurRoute(CelebRoute.Users);
                                                onRouteChange(CelebRoute.Users);
                                            }}
                                        >
                                            <div className={classes.iconWrap}>
                                                {curRoute === CelebRoute.Users ? <div className={classnames(classes.icon, {
                                                    [classes.iconSelected]:
                                                        curRoute === CelebRoute.Users,
                                                })} style={
                                                    curRoute === CelebRoute.Users
                                                        ? {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                        }
                                                        : {}
                                                }><img src={user} /></div> : <img src={user_grey} style={
                                                    curRoute === CelebRoute.Users
                                                        ? { width: 88, height: 88, overflow: "hidden" }
                                                        : {}
                                                } />}
                                                <div
                                                    style={{ fontFamily: "Poppins" }}
                                                    className={classnames(classes.text, {
                                                        [classes.textSelected]:
                                                            curRoute === CelebRoute.Users,
                                                    })}
                                                >
                                                    {formatMessage(messages.users)}
                                                </div>
                                            </div>
                                        </Button>
                                        : null
                                }

                                {/* Quiz */}
                                {
                                    auth.roles && auth.roles.includes("quiz")
                                        ?
                                        <Button
                                            className={classnames(classes.iconBtn, {
                                                [classes.iconBtnSelected]: curRoute === CelebRoute.Quiz,
                                            })}
                                            onClick={() => {
                                                setCurRoute(CelebRoute.Quiz);
                                                onRouteChange(CelebRoute.Quiz);
                                            }}
                                        >
                                            <div className={classes.iconWrap}>
                                                {curRoute === CelebRoute.Quiz ? <div className={classnames(classes.icon, {
                                                    [classes.iconSelected]:
                                                        curRoute === CelebRoute.Quiz,
                                                })} style={
                                                    curRoute === CelebRoute.Quiz
                                                        ? {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                        }
                                                        : {}
                                                }><img src={user} /></div> : <img src={user_grey} style={
                                                    curRoute === CelebRoute.Quiz
                                                        ? { width: 88, height: 88, overflow: "hidden" }
                                                        : {}
                                                } />}
                                                <div
                                                    style={{ fontFamily: "Poppins" }}
                                                    className={classnames(classes.text, {
                                                        [classes.textSelected]:
                                                            curRoute === CelebRoute.Quiz,
                                                    })}
                                                >
                                                    {formatMessage(messages.quiz)}
                                                </div>
                                            </div>
                                        </Button>
                                        : null
                                }

                                {/* Recharge */}
                                {
                                    auth.roles && auth.roles.includes("recharge")
                                        ?
                                        <Button
                                            className={classnames(classes.iconBtn, {
                                                [classes.iconBtnSelected]: curRoute === CelebRoute.Recharge,
                                            })}
                                            onClick={() => {
                                                setCurRoute(CelebRoute.Recharge);
                                                onRouteChange(CelebRoute.Recharge);
                                            }}
                                        >
                                            <div className={classes.iconWrap}>
                                                {curRoute === CelebRoute.Recharge ? <div className={classnames(classes.icon, {
                                                    [classes.iconSelected]:
                                                        curRoute === CelebRoute.Recharge,
                                                })} style={
                                                    curRoute === CelebRoute.Recharge
                                                        ? {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", width: 88, height: 88, overflow: "hidden"
                                                        }
                                                        : {}
                                                }><img src={user} /></div> : <img src={user_grey} style={
                                                    curRoute === CelebRoute.Recharge
                                                        ? { width: 88, height: 88, overflow: "hidden" }
                                                        : {}
                                                } />}
                                                <div
                                                    style={{ fontFamily: "Poppins" }}
                                                    className={classnames(classes.text, {
                                                        [classes.textSelected]:
                                                            curRoute === CelebRoute.Recharge,
                                                    })}
                                                >
                                                    {formatMessage(messages.recharge)}
                                                </div>
                                            </div>
                                        </Button>
                                        : null
                                }

                            </div>
                        </section>
                        <section className={classes.rightBar}>
                            <section className={classes.topBar}>
                                <section className={classes.left}>
                                    <div className={classes.routeName}>{getRouteName()}</div>
                                </section>
                                <section className={classes.right}>
                                    <Button
                                        className={classes.dp}

                                        size="large"
                                    >
                                        <img
                                            src={dp}
                                            onClick={(e) => {
                                                setAnchorEl(e.currentTarget);
                                                setOpenMenu(true)
                                            }}
                                            alt="Profile"
                                            className={classes.displayImg}
                                        />
                                    </Button>


                                    <Button onClick={(e) => {
                                        setAnchorEl(e.currentTarget);
                                        setOpenMenu(true)
                                    }} className={classes.celebName}>{celebName}</Button>

                                    <Menu
                                        id="simple-menu"
                                        keepMounted
                                        anchorEl={anchorEl}
                                        open={openMenu}
                                        onClose={handleCloseMenu}
                                        style={{
                                            top: 50,
                                            right: 400,

                                        }}
                                    >
                                        <MenuItem

                                            onClick={() => {
                                                handleCloseMenu();
                                                dispatch(
                                                    AllActions.Auth.logout()
                                                );
                                            }}>Logout</MenuItem>
                                    </Menu>
                                </section>
                            </section>
                            <section className={classes.content}>{children}</section>
                        </section>
                    </div>
                </div>
            ) : (
                <div className={classes.smallDevice}>
                    <section className={classes.top}>
                        <img src={logo} alt="Logo" className={classes.logoSmall} />
                    </section>
                    <section className={classes.middle}>
                        <div className={classes.routeNameSmall}>{getRouteName()}</div>
                        <div className={classes.contentSmall}>{children}</div>
                    </section>
                    <section className={classes.bottom}>
                        <Button
                            onClick={() => {
                                setCurRoute(CelebRoute.Dashboard);
                                onRouteChange(CelebRoute.Dashboard);
                            }}
                            className={classes.iconBtnSmall}
                        >
                            <div className={classes.iconWrapSmall}>
                                <FontAwesomeIcon
                                    className={classnames(classes.iconSmall, {
                                        [classes.iconSelectedSmall]:
                                            curRoute === CelebRoute.Dashboard,
                                    })}
                                    icon={faThLarge}
                                />
                                <div
                                    className={classnames(classes.textSmall, {
                                        [classes.textSelectedSmall]:
                                            curRoute === CelebRoute.Dashboard,
                                    })}
                                >
                                    {formatMessage(messages.dashboard)}
                                </div>
                            </div>
                        </Button>
                        <Button
                            className={classes.iconBtnSmall}
                            onClick={() => {
                                setCurRoute(CelebRoute.Orders);
                                onRouteChange(CelebRoute.Orders);
                            }}
                        >
                            <div className={classes.iconWrapSmall}>
                                <FontAwesomeIcon
                                    className={classnames(classes.iconSmall, {
                                        [classes.iconSelectedSmall]:
                                            curRoute === CelebRoute.Orders,
                                    })}
                                    icon={faEnvelopeOpenText}
                                />
                                <div
                                    className={classnames(classes.textSmall, {
                                        [classes.textSelectedSmall]:
                                            curRoute === CelebRoute.Orders,
                                    })}
                                >
                                    {formatMessage(messages.orders)}
                                </div>
                            </div>
                        </Button>
                        <Button
                            className={classes.iconBtnSmall}
                            onClick={() => {
                                setCurRoute(CelebRoute.Influencers);
                                onRouteChange(CelebRoute.Influencers);
                            }}
                        >
                            <div className={classes.iconWrapSmall}>
                                <FontAwesomeIcon
                                    className={classnames(classes.iconSmall, {
                                        [classes.iconSelectedSmall]:
                                            curRoute === CelebRoute.Influencers,
                                    })}
                                    icon={faEnvelopeOpenText}
                                />
                                <div
                                    className={classnames(classes.textSmall, {
                                        [classes.textSelectedSmall]:
                                            curRoute === CelebRoute.Influencers,
                                    })}
                                >
                                    {formatMessage(messages.influencers)}
                                </div>
                            </div>
                        </Button>
                        <Button
                            className={classes.iconBtnSmall}
                            onClick={() => {
                                setCurRoute(CelebRoute.Profile);
                                onRouteChange(CelebRoute.Profile);
                            }}
                        >
                            <div className={classes.iconWrapSmall}>
                                <FontAwesomeIcon
                                    className={classnames(classes.iconSmall, {
                                        [classes.iconSelectedSmall]:
                                            curRoute === CelebRoute.Profile,
                                    })}
                                    icon={faUser}
                                />
                                <div
                                    className={classnames(classes.textSmall, {
                                        [classes.textSelectedSmall]:
                                            curRoute === CelebRoute.Profile,
                                    })}
                                >
                                    {formatMessage(messages.profile)}
                                </div>
                            </div>
                        </Button>
                    </section>
                </div>
            )}
        </div>
    );
}

export default withStyles(styles)(withIntl(CelebNavigation));
