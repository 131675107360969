import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route } from "react-router-dom";
import Main from "../../scenes/Main/index";
// import Landing from "../Landing";

import { connect } from 'react-redux';
import { compose, bindActionCreators } from "redux";

import Login from "../Auth";
import AllActions from '../../store/actions/index';

function App(props: Props) {

  useEffect(() => {
    props.authCheckStatus();
  },[])
  return (
    <>
      <Helmet defaultTitle="MadFan">
        <meta name="description" content="Mad fan by Codebucket" />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        { 
          !props.token ? 
          <Switch>
            <Route
              path="/"
              render={(props: any) => <Login {...props} />}
            />
          </Switch>
          :
          <Switch>
            <Route path="*" render={(props: any) => <Main {...props} />} />
          </Switch>
        }
        
      </Suspense>
    </>
  );
}

const mapStateToProps = (state: any) =>{
  return {
      token: state.auth.token,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
    authCheckStatus : () => dispatch(AllActions.Auth.authCheckStatus()),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(App);
