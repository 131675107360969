/**
*
* Auth Component
*
*/
import React, {useState, useEffect} from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import Login from "../../components/Login";
import {
    Snackbar
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

import { connect } from 'react-redux';
import { compose, bindActionCreators } from "redux";


import AllActions from '../../store/actions/index';

type Props = {
/** internal */
classes: any;

/** internal */
formatMessage: Function;

/** class applied to top level container */
className?: any;

/** style applied to top level container */
style?: Object;
};

function Auth(props: Props) {
    const {
    classes,
    style,
    className,
    formatMessage,
    } = props;

    
    const [snackBarOpen,setSnackBarOpen] = React.useState(false);

    const handleAuth =  (user: any, pass: any) =>{
        props.onAuth(user, pass);
        
    }

    useEffect(()=> {
        if(props.error && props.error.length > 0){
            setSnackBarOpen(true);
        }
    }, [props.error])


    // console.log(props)
   
    return (
        <div className={className} style={style}>
            <Login
                handleSubmit={handleAuth}
            />
            <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={() => setSnackBarOpen(false)}>
                <Alert onClose={() => setSnackBarOpen(false)} severity="error">
                    {props.error}
                </Alert>
            </Snackbar>
        </div>
    );

}

const mapStateToProps = (state: any) =>{
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuth : state.auth.token !== null,
    }
}
  
const mapDispatchToProps = (dispatch) =>{
    return {
        onAuth : (email, password) => dispatch(AllActions.Auth.auth(email, password)),
    }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(withStyles(styles)(withIntl(Auth)));