const styles = (theme: any) => ({
  container: {
    fontFamily: "Poppins",
    backgroundColor: "#f3f6fd",
    width: "100%",

  },
  largeDevice: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: 80,
    paddingBottom: 54,
  },
  leftBar: { display: "flex", flexFlow: "column" },
  wrap: { display: "flex", flexFlow: "column" },
  logo: { height: 64, width: 90, margin: "32px 56px 32px 72px" },
  iconBtn: {
    padding: "18px 52px 18px 64px",
    textTransform: "none",
    borderRadius: "0px 20px 20px 0px",
    marginBottom: 32,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconBtnSelected: {
    backgroundColor: "#FFFFFF",
    "box-shadow": "0 13px 26px 0 rgba(255, 135, 168, 0.1)",
  },
  iconWrap: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  text: {
    marginTop: 6,
    fontWeight: 500,
    fontSize: 16,
  },
  textSelected: { fontWeight: 900 },
  icon: {
    color: "rgba(0,0,0,0.5)",
    fontSize: 32,
    width: 32,
    height: 32,
    transition: "padding 0.1s ease-in-out",
  },
  iconSelected: {
    color: "#FFFFFF",
    backgroundColor: "#e90041",
    borderRadius: "50%",
    padding: 28,
  },
  rightBar: {
    width: "100%",
    marginLeft: 60,
    marginTop: 30,
  },
  topBar: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    height: "fit-content",
    // alignItems: "center",
  },
  left: {},
  routeName: {
    fontSize: 46,
    fontWeight: 700,
  },
  right: {},
  notifButton: {
    position: "relative" as "relative",
  },
  notifImg: {
    width: 36,
    height: 36,
  },
  noOfNotifs: {
    position: "absolute" as "absolute",
    fontFamily: "Poppins",
    color: "#fff",
    backgroundColor: "#e90041",
    width: 24,
    height: 24,
    borderRadius: "50%",
    fontWeight: "bold",
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #fff",
    boxSizing: "border-box",
    right: 6,
    top: 8,
  },
  dp: { marginLeft: 32 },
  displayImg: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  celebName: {
    marginLeft: 24,
    textTransform: "none",
    fontWeight: 600,
    fontSize: 24,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  content: {
    marginTop: 30,
  },
  smallDevice: {
    maxWidth: '100%',
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    "box-shadow": "0 3px 26px 0 rgba(233, 0, 65, 0.1)",
    padding: "8px 16px",
  },
  logoSmall: {
    height: 36,
    width: "auto",
  },
  noOfCoinSmall: { marginRight: 8 },
  notifButtonSmall: {
    position: "relative" as "relative",
  },
  notifImgSmall: {
    width: 28,
    height: 28,
  },
  noOfNotifsSmall: {
    position: "absolute" as "absolute",
    fontFamily: "Poppins",
    color: "#fff",
    backgroundColor: "#e90041",
    width: 14,
    height: 14,
    borderRadius: "50%",
    fontWeight: "bold",
    fontSize: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #fff",
    boxSizing: "border-box",
    right: 12,
    top: 3,
  },
  middle: { marginTop: 8, padding: 16 },
  routeNameSmall: { fontSize: 36, fontWeight: 700 },
  contentSmall: { margiTop: 12 },
  bottom: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#FFF",
    paddingBottom: 28,
  },
  iconBtnSmall: {
    textTransform: "none",
    "&:hover": { backgroundColor: "transparent" },
  },
  iconWrapSmall: {},
  iconSmall: { fontSize: 16, color: "rgba(0,0,0,0.5)" },
  iconSelectedSmall: { color: "#e90041" },
  textSmall: { fonSize: 12 },
  textSelectedSmall: { fontWeight: 700 },
});

export default styles;
