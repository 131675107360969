/**
 * CelebNavigation Messages
 *
 * This contains all the text for the CelebNavigation Component
 *
 */

import { defineMessages } from "react-intl";

export const scope = "app.component.CelebNavigation";

export default defineMessages({
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: "Dashboard",
  },
  fanRequest: {
    id: `${scope}.fanRequest`,
    defaultMessage: "Fan Request",
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: "Orders",
  },
  influencers: {
    id: `${scope}.influencers`,
    defaultMessage: "Influencers",
  },
  managers: {
    id: `${scope}.managers`,
    defaultMessage: "Managers",
  },
  coupons: {
    id: `${scope}.coupons`,
    defaultMessage: "Coupons",
  },
  referrals: {
    id: `${scope}.referrals`,
    defaultMessage: "Referrals",
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: "Users",
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: "Profile",
  },
  quiz: {
    id: `${scope}.quiz`,
    defaultMessage: "Quiz",
  },
  question: {
    id: `${scope}.question`,
    defaultMessage: "Question",
  },
  recharge: {
    id: `${scope}.recharge`,
    defaultMessage: "Recharge",
  },
  admin: {
    id: `${scope}.admin`,
    defaultMessage: "Admin",
  },
});
