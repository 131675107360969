/**
*
* Auth
*
*/


import Layout from "./layout";




export default Layout;