/**
 *
 * Congratulations Component
 *
 */
import React, { useEffect, useState} from "react";
import styles from "./styles";
import withIntl from "../../utils/withIntl";
import messages from "./messages";
import { withStyles } from "@material-ui/core/styles";
import { Function } from "lodash";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '../../images/logo.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type Props = {
  /** internal */
  classes: any;

  /** internal */
  formatMessage: Function;

  /** class applied to top level container */
  className?: any;

  /** style applied to top level container */
  style?: Object;
  type: string;
  handleSubmit: Function;
  onClose:(message:string)=>void;
};

function Login(props: Props) {
  const {style, formatMessage, type,onClose } = props;
    const [name, setName] = useState('');
    const [pass, setPass] = useState('');

    const handleSubmit = (e: any) =>{
      e.preventDefault();
      props.handleSubmit(name,pass);
    }

  const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <div className={classes.avatar}>
              <img src={LockOutlinedIcon}/>
            </div>

            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="text"
                  value={name}
                  onChange={e=>{setName(e.target.value)}}
                  label="Username"
                  name="username"
                  color="secondary"
                  autoFocus
              />
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  value={pass}
                  onChange={e=>{setPass(e.target.value)}}
                  id="password"
                  color="secondary"
                  autoComplete="current-password"
              />
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
              >
                Sign In
              </Button>
            </form>
          </div>
        </Container>
    );
}

export default (withIntl(Login));
