/**
 *
 * app.js
 *
 * This is the entry file for the application,only setup and boilerplate
 * code
 */
import React from "react";
import ReactDOM from "react-dom";
import App from "./scenes/App";
import { IntlProvider } from "react-intl";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import './index.css';
  

import { createStore ,combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import AuthReducer from './store/reducers/auth';


const rootReducer = combineReducers({
  auth:AuthReducer,
});

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
  ));

  console.log(store.getState().auth.token)

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="en">
      <HashRouter>
        <App />
      </HashRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
