export const CelebRoute = {
  Dashboard: "dashboard",
  Orders: "orders",
  Influencers: "influencers",
  Managers: "managers",
  Coupons: "coupons",
  Referrals: "referrals",
  Users: "users",
  Profile: "profile",
  Quiz: "quiz",
  Recharge:"recharge",
  Question:"question",
  Admin:"Admin"
};
