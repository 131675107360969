import React, { useState, useEffect, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    MEDIA_DEVICE_DESKTOP,
    MEDIA_DEVICE_MOBILE,
    BREAKING_POINT,
} from "../../utils/constants";
import image from '../../images/sample.png'
import { useWindowWidth } from "@react-hook/window-size";
import CelebNavigation from '../../components/CelebNavigation';

const Dashboard = React.lazy(() => import('../Dashboard'));
const Profile = React.lazy(() => import('../Profile'));
const Request = React.lazy(() => import('../Request'));
const Orders = React.lazy(() => import('../Orders'));
const Influencers = React.lazy(() => import('../Influencers'));
const Managers = React.lazy(() => import('../Managers'));
const Coupons = React.lazy(() => import('../Coupons'));
const Referrals = React.lazy(() => import('../Referrals'));
const Users = React.lazy(() => import('../Users'));
const Quiz = React.lazy(() => import('../Quiz'));
const Recharge = React.lazy(() => import('../Recharge'));
const Questions = React.lazy(() => import('../Questions'));
const Admin = React.lazy(() => import('../Admin'));



// const SearchCeleb = lazy(() => import("../SearchCeleb"));


function Main(props: any) {
    //   const { load } = props;

    //   useEffect(() => {
    //     // load(true);
    //   }, []);

    const auth = useSelector((state) => state.auth)
    // console.log(auth)

    const mediaDevice =
        useWindowWidth() > BREAKING_POINT
            ? MEDIA_DEVICE_DESKTOP
            : MEDIA_DEVICE_MOBILE;

    const changeRoute = (route) => {

        if (route === "dashboard") {
            console.log(route)
            props.history.push("/");
        }
        else {
            props.history.push("/" + route);
        }


    }

    function onProfileClick() {

    }


    return (
        <>
            <div id="root">
                {/* <AppBar
          device={mediaDevice === MEDIA_DEVICE_DESKTOP ? "large" : "small"}
          onLogin={() => setLoginModal(true)}
          isLoggedIn={!!props.token}
        /> */}

                {/* celeb navigaion */}
                <CelebNavigation
                    device={mediaDevice === MEDIA_DEVICE_DESKTOP ? "large" : "small"}
                    noOfNotifs={5}
                    dp={image}
                    celebName="Admin"
                    onRouteChange={changeRoute}
                >
                    <Switch>
                        <Suspense fallback={() => { }}>
                            {

                                auth.roles && auth.roles.includes("order")
                                    ?
                                    <>
                                        < Route
                                            path="/requests"
                                            exact
                                            render={(componentProps) => <Request {...componentProps} />}
                                        />
                                        <Route
                                            path="/orders"
                                            exact
                                            render={(componentProps) => <Orders {...componentProps} />}
                                        />
                                    </>
                                    : null
                            }

                            {

                                auth.roles && auth.roles.includes("influencer")
                                    ?
                                    <>
                                        < Route
                                            path="/influencers"
                                            exact
                                            render={(componentProps) => <Influencers {...componentProps} />}
                                        />
                                        <Route
                                            path="/managers"
                                            exact
                                            render={(componentProps) => <Managers {...componentProps} />}
                                        />
                                        <Route
                                            path="/profile/:id"
                                            exact
                                            render={(componentProps) => <Profile {...componentProps} />}
                                        />
                                    </>
                                    : null
                            }

                            {

                                auth.roles && auth.roles.includes("coupon")
                                    ?
                                    <Route
                                        path="/coupons"
                                        exact
                                        render={(componentProps) => <Coupons {...componentProps} />}
                                    />
                                    : null
                            }

                            {

                                auth.roles && auth.roles.includes("referral")
                                    ?
                                    < Route
                                        path="/referrals"
                                        exact
                                        render={(componentProps) => <Referrals {...componentProps} />}
                                    />
                                    : null
                            }


                            {

                                auth.roles && auth.roles.includes("user")
                                    ?
                                    <Route
                                        path="/users"
                                        exact
                                        render={(componentProps) => <Users {...componentProps} />}
                                    />
                                    : null

                            }


                            {
                                auth.roles && auth.roles.includes("quiz")
                                    ?
                                    <>
                                        <Route
                                            path="/quiz"
                                            exact
                                            render={(componentProps) => <Quiz {...componentProps} />}
                                        />
                                        <Route
                                            path="/question/:id"
                                            exact
                                            render={(componentProps) => <Questions {...componentProps} />}
                                        />
                                    </>
                                    : null
                            }

                            {
                                auth.roles && auth.roles.includes("recharge")
                                    ?
                                    <Route
                                        path="/recharge"
                                        exact
                                        render={(componentProps) => <Recharge {...componentProps} />}
                                    />
                                    : null
                            }

                            {
                                auth.roles && auth.roles.includes("admin")
                                    ?
                                    <Route
                                        path="/admin"
                                        exact
                                        render={(componentProps) => <Admin {...componentProps} />}
                                    />
                                    : null
                            }


                            <Route
                                path="/"
                                exact
                                render={(componentProps) => <Dashboard {...componentProps} />}
                            />
                        </Suspense>
                    </Switch>
                </CelebNavigation>
            </div>
        </>
    );
}

export default Main;
